import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';


class SideBox extends Component {

    componentDidMount() {
        window.Frost.init();
        window.Frost.load();
        window.handleSideBarMenu();
    }
    render() {

        return (
            <>
                <div class="about-sidebox">
                    <a href="javascript:void(0)" class="closebtn bg-primary">×</a>
                    <div class="about-section">
                        <div class="contact-box">
                            <div class="contact-form">
                                <form method="post" class="dzForm dezPlaceAni" action="script/contact.php">
                                    <h6 class="title">Contact Us</h6>
                                    <div class="dzFormMsg"></div>
                                    <input type="hidden" value="Contact" name="dzToDo" />
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label>Full Name</label>
                                                <input name="dzName" type="text" required class="form-control " />
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label>Your Email Address</label>
                                                <input name="dzEmail" type="email" class="form-control" required />
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <label>Your Message</label>
                                                <textarea name="dzMessage" rows="4" class="form-control" required></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="g-recaptcha" data-sitekey="6LcCqxUdAAAAALY1Wv8jdqVc8SGQdyfpsLeB8K5d"
                                                data-callback="verifyRecaptchaCallback"
                                                data-expired-callback="expiredRecaptchaCallback"></div>
                                            <input class="form-control d-none" style={{ "display": "none" }} data-recaptcha="true"
                                                required data-error="Please complete the Captcha" />
                                        </div>
                                        <div class="col-md-12">
                                            <button name="submit" type="submit" value="Submit"
                                                class="site-button gradient shadow radius-md button-lg black">Send
										Message</button>
                                        </div>
                                    </div>
                                </form>
                                <a href="https://www.mesh.ac" class="site-button map-btn"><span>Visit Crowdsale</span><span>Close</span></a>
                            </div>
                            <div class="contact-info">
                                <div class="col">
                                    <div>
                                        <h5 class="title">Available on</h5>
                                        <p>Polygon Network</p>
                                    </div>
                                </div>
                                <div class="col">
                                    <div>
                                        <h5 class="title">Find us on</h5>
                                        <ul>
                                            <li><a href="#">Telegram</a></li>
                                            <li><a href="#">Discord</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="gallery-area content scroll-off" style={{ "backgroundImage": "url(images/background/bg1.jpg)" }}>
                            <div class="row lightgallery" id="masonry">
                                <div class="col-md-6 col-6 card-container">
                                    <div class="gallery-box">
                                        <div class="gallery-media check-km" data-exthumbimage="images/mesh/MESH Token Flow Graphic - I.png"
                                            data-src="images/mesh/MESH Token Flow Graphic - I.png" title="MESH Phase I">
                                            <img src="images/mesh/MESH Token Flow Graphic - I.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 card-container">
                                    <div class="gallery-box">
                                        <div class="gallery-media check-km" data-exthumbimage="images/mesh/MESH Token Flow Graphic - II.png"
                                            data-src="images/mesh/MESH Token Flow Graphic - II.png" title="MESH Phase II">
                                            <img src="images/mesh/MESH Token Flow Graphic - II.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 card-container">
                                    <div class="gallery-box">
                                        <div class="gallery-media check-km" data-exthumbimage="images/mesh/MESH Token Flow Graphic - III.png"
                                            data-src="images/mesh/MESH Token Flow Graphic - III.png" title="MESH Phase III">
                                            <img src="images/mesh/MESH Token Flow Graphic - III.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 card-container">
                                    <div class="gallery-box">
                                        <div class="gallery-media check-km" data-exthumbimage="images/mesh/MESH Token Flow Graphic - IV.png"
                                            data-src="images/mesh/MESH Token Flow Graphic - IV.png" title="MESH Phase IV">
                                            <img src="images/mesh/MESH Token Flow Graphic - IV.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12 card-container wow flipInX" data-wow-duration="0.80s" data-wow-delay="0.5s">
                                    <div class="gallery-box">
                                        <div class="gallery-media" data-exthumbimage="images/mesh/MESH Token Flow - Whitepaper.png"
                                            data-src="images/mesh/MESH Token Flow - Whitepaper.png" title="MESH Token Flow">
                                            <img src="images/mesh/MESH Token Flow - Whitepaper.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default SideBox;