import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home1 from './pages/home1';


class Router extends Component {
    render() {
        return (
            <>
                <BrowserRouter basename={'/react/'}>
                    <div className="content-body">
                        <div className="container-fluid">
                            <div className="row">
                                <Switch>
                                    <Route path='/' component={Home1} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </BrowserRouter>
            </>
        );
    }
}

export default Router;
